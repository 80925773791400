<template>
  <div>
      <v-card>
          <h2 class="pl-2">
              {{ $t('product.title')}}
          </h2>
        <v-btn
            color="blue"
            class="float-right mr-10 white--text"
            @click.stop="addOrEditArticle()"
          >
          {{ $t('product.add')}}
          <v-icon class="ml-2 white--text">
            mdi-ice-cream
          </v-icon>
        </v-btn>
        <div style="clear:both"></div>
        <v-data-table
          :headers=headers
          :items=products
          :options.sync="options"
          :server-items-length="serverItemsLength"
        >
        <template
          slot="item.actions"
          slot-scope="props"
          class="justify-center"
          >
          <v-btn
            class="mx-2"
            icon
            @click="addOrEditArticle(props.item.id !== 0 ? JSON.parse(JSON.stringify(props.item)): null)"
          ><v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            icon
            @click="deleteItem(props.item)"
          ><v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>

        </v-data-table>
      </v-card>

      <confirm-delete-dialog
        endPoint="/article/DeleteArticle"
        ref="confirmDeleteDialog"
        @submit="getDataAsync"
      ></confirm-delete-dialog>
  </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'Product',
  components: {
    ConfirmDeleteDialog
  },
  data () {
    return {
      headers: [
        { text: this.$t('product.name'), value: 'name', sortable: false },
        { text: this.$t('product.actions'), value: 'actions', width: '10%', sortable: false }
      ],
      products: [],
      options: {},
      serverItemsLength: 0,
      endpoint: '/article/GetArticles'
    }
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint

      if (this.options.page) {
        url += '?'
        url += 'options.page=' + this.options.page + '&' +
        'options.itemsPerPage=' + this.options.itemsPerPage + '&' +
        'options.sortBy=id' + '&' +
        'options.sortDesc=false' + '&type=4' + '&DateFilter=3'
      }

      const result = await this.$http.get(url)
      this.products = result.data.results
      this.serverItemsLength = result.data.totalCount
    },
    deleteItem (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = item.name
      this.$refs.confirmDeleteDialog.id = item.id
    },
    async addOrEditArticle (item) {
      if (item) {
        await this.$router.push({ name: 'AddOrEditProduct', params: { id: item.id, type: 4 } })
      } else {
        await this.$router.push({ name: 'AddOrEditProduct', params: { type: 4 } })
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
